import React, { useEffect, useState} from 'react'
// import PropTypes from 'prop-types'


const Modal = (props) => {

    return (
   
        <div className={`modal ${props.isActive}`}>
            <div className="modal-background"></div>
            <div className="modal-content">{props.component}</div>
            <button
                className={`modal-close is-large`}
                aria-label="close"
                onClick={props.onCloseClick}
            ></button>
        </div>

    )
}
  
export default Modal
  