import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const ScaleUp = ({ children, props }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  return (
    <motion.div
      animate={
        inView
          ? {
              scale: [0, 1.2, 1],
              opacity: [0, 1],
            }
          : {
              scale: 0,
              opacity: [0, 0],
            }
      }
      transition={{ duration: 0.8, delay: 0 }}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

ScaleUp.propTypes = {
  children: PropTypes.node.isRequired,
}

// ScaleUp.defaultProps = {
//   duration: 0.8,
//   delay: 0,
// }

export default ScaleUp
