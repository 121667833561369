import React from "react"
import Highlight from "./highlight"
import SlideInTop from "../utility/slideInTopAnimation"
import SlideInBottom from "../utility/slideInBottomAnimation"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const HighlightsSeries = props => (
  <div className={`section ${props.isGradient} is-medium is-relative`}>
    {props.fluid && (
        <Img
          fluid={props.fluid}
          style={{
            position: "absolute",
            overflow: "hidden",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            opacity: "0.2",
          }}
          className="image-wrapper-height-auto"
          alt={props.alt}
        />
      )}
    <div className="container">
      <div className="columns is-multiline">
        {props.illustration && (
          <>
          <div className="column is-8 is-offset-2" style={{marginTop: '-200px', zIndex: '100'}}>{props.illustration}</div>
          <div className="column is-2"></div>
        </>
        )}
        <div className="column is-2"></div>
        <div className="column is-8">
        {props.mainTitle && (
          <div className="content is-large">
            <h1 className={`title is-1 ${props.titleColour} is-bottom-bordered`} style={{ paddingBottom: "50px", }}><SlideInBottom>{props.mainTitle}</SlideInBottom></h1>
          </div>
        )}
          <div className="content">
            <h3 style={{ paddingBottom: "50px", lineHeight: "1.5"}}>
              <SlideInTop>{props.title}</SlideInTop>
            </h3>
            {props.mainOne && (
            <Highlight
              main={props.mainOne}
              description={props.descriptionOne}
            />
            )}
            {props.mainTwo && (
            <Highlight
              main={props.mainTwo}
              description={props.descriptionTwo}
            />
            )}
            {props.mainThree && (
            <Highlight
              main={props.mainThree}
              description={props.descriptionThree}
            />
            )}
            {props.mainFour && (
              <Highlight
                main={props.mainFour}
                description={props.descriptionFour}
              />
            )}
            {props.mainFive && (
              <Highlight
                main={props.mainFive}
                description={props.descriptionFive}
              />
            )}
            {props.mainSix && (
              <Highlight
                main={props.mainSix}
                description={props.descriptionSix}
              />
            )}
            {props.mainSeven && (
              <Highlight
                main={props.mainSeven}
                description={props.descriptionSeven}
              />
            )}
            {props.mainEight && (
              <Highlight
                main={props.mainEight}
                description={props.descriptionEight}
              />
            )}
            {props.mainNine && (
              <Highlight
                main={props.mainNine}
                description={props.descriptionNine}
              />
            )}
            {props.mainTen && (
              <Highlight
                main={props.mainTen}
                description={props.descriptionTen}
              />
            )}
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  </div>
)

HighlightsSeries.propTypes = {
  isGradient: PropTypes.string,
  titleColour: PropTypes.string
}

HighlightsSeries.defaultProps = {
  isGradient: "",
  titleColour: "is-white"
}

export default HighlightsSeries
