import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const SlideInLeft = ({ children }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  return (
    <motion.div
      animate={
        inView
          ? {
              x: [-70, 0],
              opacity: [0, 1],
            }
          : {
              x: [0, 0],
              opacity: [0, 0],
            }
      }
      transition={{ duration: 0.6 }}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

SlideInLeft.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SlideInLeft
