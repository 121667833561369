import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '../../pageContext'
import { StaticImage } from "gatsby-plugin-image"

const initialValues = {
  email: "",
  name: "",
  last_name: "",
  organization: "",
  privacy: ""
};

const LaundryNews = () => {

    const [ formStatus, setFormStatus ] = useState(null);
  
    // Ricaviamo la lingua dal contesto della pagina 
    const { lang } = usePageContext()
    
    // React hook per gestire lo stato dei values
    const [values, setValues] = useState(initialValues);

    // List "Laundry" ID
    const LaundryITListID = "esduf"
    const LaundryENListID = "VbKGs"
    const LaundryESListID = "vKbGG"

    //Mailercloud API
    const FORM_URL = `https://cloudapi.mailercloud.com/v1/contacts`;

    //Handle input change
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
        console.log(values)
        e.preventDefault();
        const dataForApi = {
          email: values.email,
          name: values.name,
          last_name: values.last_name,
          organization: values.organization,
          custom_fields: {
            Zuu: values.privacy, //privacy field id
            KaSa: lang === 'it' ? "Italiano" : lang === "en" ? "Inglese" : lang === "es" ? "Spagnolo" : "Inglese", //language field id
            ZuE: new Date().toLocaleString(), //optin date and time
          },
          list_id: lang === 'it' ? LaundryITListID : lang === "en" ? LaundryENListID : lang === "es" ? LaundryESListID : LaundryENListID, 
        }
        console.log(dataForApi)
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: JSON.stringify(dataForApi),
                    headers: {
                        'Authorization': 'bAsfB-ff48b20e62660770134d4d99f6557a7f-bf044791135995e0deea1a8a66445727',
                        'Content-Type': 'application/json',
                    },
                }
            );
            setValues('');
            console.log(response.status)
            if (response.status === 201) {
                setFormStatus('SUCCESS')
                return;
            }
        } catch (err) {
            setValues(setFormStatus('ERROR'))
            console.log(err);
        }
    };

    const { t } = useTranslation();

    return (

    (formStatus === null) ? 
      //Form effettivo
      <div className="bg-gray-50 rounded">
        <div className="maingrad px-8 py-16 rounded-t">
          <div className='md:flex md:items-center'>
            <div className='md:w-1/3 text-center'>
              <StaticImage src="../../images/cta-icon.png" className="rounded-full bg-white" width={100} height={100} alt="Industrial Electronics at CTA" />
            </div>
            <div className='md:w-2/3'>
              <p className="text-2xl text-white font-semibold text-left">{t('subscriberform.title')}</p>
              <p className="font-semibold text-left">{t('subscriberform.subtitle')}</p>
            </div>
          </div>
        </div>
        <div className="px-16 py-10">
          <form
            method="POST"
            name="generalsignup"
            onSubmit={handleSubmit}
          >
            <div className="md:flex md:space-x-4">
                <div className='md:w-1/2'>
                  <label className="font-semibold">
                    {t('subscriberform.firstName')}
                  </label>
                  <input
                    required
                    className="p-2 w-full drop-shadow"
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                  ></input>
                </div>
                <div className='md:w-1/2'>
                  <label className="font-semibold">
                    {t('subscriberform.lastName')}
                  </label>
                  <input
                    required
                    className="p-2 w-full drop-shadow"
                    type="text"
                    name="last_name"
                    onChange={handleInputChange}
                  ></input>
                </div>
            </div>
            <div className="md:flex md:space-x-4 pt-4">
                <div className='md:w-1/2'>
                  <label className="font-semibold">
                    {t('subscriberform.companyName')}
                  </label>
                  <input
                    required
                    className="p-2 w-full drop-shadow"
                    type="text"
                    name="organization"
                    onChange={handleInputChange}
                  ></input>
                </div>
                <div className="md:w-1/2">
                  <label className="font-semibold">
                    {t('subscriberform.email')}
                  </label>
                  <input
                    required
                    className="p-2 w-full drop-shadow"
                    type="text"
                    name="email"
                    onChange={handleInputChange}
                  ></input>
                </div>
            </div>
            <div className="pt-4">
                  <input
                    required
                    type="checkbox"
                    name="privacy"
                    value="Acconsento al trattamento dei dati come da Privacy Policy"
                    onChange={handleInputChange}
                  />{' '}
                  {t('subscriberform.privacy')}{' '}
                  Privacy Policy
            </div>
            <div className='flex justify-center pb-4 pt-8'>
              <button
                type="submit"
                className="greenBtnNormal"
              >
                {t('subscriberform.send')}
              </button>
            </div>
          </form>
        </div>
      </div>        
    :
    (formStatus === 'SUCCESS') ? 
      // Messaggio di successo
        <div className="bg-gray-50 rounded">
          <div className="maingrad px-8 py-16 rounded-t">
            <p className="title is-2 is-white">
              {t('subscriberform.successTitle')}
            </p>
            <p className="pt-4 text-white font-semibold text-xl">
              {t('subscriberform.saluto')},
            </p>
            <p className="pt-4 text-white font-semibold text-xl">CTA Team</p>
          </div>
        </div>
    :
      // Messaggio di errore
        <div className="bg-gray-50 rounded">
          <div className="maingrad px-8 py-16 rounded-t">
            <p className="title is-2 is-white">
              {t('SignUpFormGeneral.errorTitle')}
            </p>
            <p className="pt-4 text-white font-semibold text-xl">
              {t('SignUpFormGeneral.saluto')},
            </p>
            <p className="pt-4 text-white font-semibold text-xl">CTA Team</p>
          </div>
        </div>
      )
}

export default LaundryNews
